import React, { Component } from 'react'
import Modal from "react-modal";
import { Icon } from "@iconify/react";
import Skeleton from "react-loading-skeleton";
import Slider from 'react-slick';
import { connect } from 'react-redux';
import { getSpareDomain } from '../../store/ducks/spareDomain/actions';
import { withNamespaces } from 'react-i18next';
// import NewFeature from './NewFeature';
// import { isDesktop } from 'react-device-detect';

export class BackupUrlModal extends Component {
  constructor(props) {
    super(props);
    const t = this.props.t;
    this.state = {
      header: t("global.new-feature-added"),
      slideCount: 0,
      activeDomain: [],
      nonActiveDomain: [],
      loading: true,
      isLogin: localStorage.getItem('accessToken') ? true : false
    };
    this.sliderRef = React.createRef();
  }

  componentDidMount() {
    if (this.state.isLogin) {
      this.props.getSpareDomain();
    }
  }

  shouldComponentUpdate(nextProps, nextState) {
    if (nextProps.spare_domain_list !== undefined && nextProps.spare_domain_list !== this.props.spare_domain_list) {
      if (nextProps.spare_domain_list.responseCode === 200 && nextProps.spare_domain_list.msgType === 'success') {
        this.setState({
          activeDomain: nextProps.spare_domain_list.data.active_domain,
          nonActiveDomain: nextProps.spare_domain_list.data.inactive_domain,
          loading: false
        });
      }
    }

    return true;
  }

  componentDidUpdate() {
    // const t = this.props.t;
    const slideCount = this.sliderRef.current?.innerSlider?.state?.slideCount;
    if (slideCount && slideCount !== this.state.slideCount) {
      this.setState({ slideCount });
    }
  }

  imageImport = (r) => {
    let images = {};
    r.keys().map((item) => {
      return (images[item.replace("./", "")] = r(item)["default"]);
    });
    return images;
  };

  render() {
    const t = this.props.t;
    const icons = this.imageImport(require.context("../../assets/images/icons", false, /\.(gif|png|jpe?g|svg|webp)$/));
    const settings = {
      dots: true,
      infinite: true,
      speed: 500,
      arrows: false,
      slidesToShow: 1,
      slidesToScroll: 1,
      // autoplay: true,
      autoplaySpeed: 3000,
      cssEase: "linear",
      beforeChange: (oldIndex, newIndex) => {
        switch (newIndex) {
          case 0:
            this.setState({ header: t("global.new-feature-added") })
            break;
          case this.state.slideCount - 1:
            this.setState({ header: t("global.cant-open-r77-how") })
            break;
          default:
            this.setState({ header: t("global.new-feature-added") })
            break;
        }
      }
    };
    
    let iconLanguage = "";
    switch (this.props.currentUser.language_code) {
      case "zh-CN":
        iconLanguage = "-cn";
        break;
      case "id":
        iconLanguage = "-id";
        break;
      case "ms":
        iconLanguage = "-ms";
        break;
      default:
        iconLanguage = "";
        break;
    }

    return (

      <Modal
        isOpen={this.state.isLogin ? this.props.showBackupUrlModal : this.props.showBonanzaModal}
        // isOpen={this.state.isLogin ? this.props.showBackupUrlModal : ""}
        closeTimeoutMS={500}
        portalClassName={`global-modal`}
        className="modal modal-dialog modal-dialog-centered modal-pd vip-link-modal"
        ariaHideApp={false}
        onRequestClose={this.state.isLogin ? this.props.toggleBackupUrlModal : this.props.toggleBonanzaModal}        
        // onRequestClose={this.state.isLogin ? this.props.toggleBackupUrlModal : ""}        
      >
        {!this.state.isLogin && (
          <div className="modal-content modal-content-bg-transparent">
            <div className="modal-header modal-header-bg-transparent">
              <Icon type="button" className="btn-close text-white btn-close-bg" icon="mdi:close" onClick={this.props.toggleBonanzaModal} />
            </div>

            <form onSubmit={this.props.handleSubmit}>
              <Slider {...settings} ref={this.sliderRef}>              
                <div>
                  <div className="modal-body">
                    <a href='promos-detail/161'>
                      <img src={icons[`cny-lucky-draw${iconLanguage}.webp`]} alt="xmas event" />                          
                    </a>
                  </div>
                </div>
              </Slider>
              <div className="modal-footer">
                <div className="d-flex align-items-center w-100 px-5">
                  <input type="checkbox" id="dont-show-check" value="showModal" onChange={(e) => this.props.setDontShowBonanza(e)} />
                  <label className="ms-1" style={{ color: "#f6faff" }} htmlFor="dont-show-check">
                    {t("global.dont-ask-again")}
                  </label>
                </div>
              </div>
            </form>
        </div>
        )}

        {this.state.isLogin && (
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title" id="vip-link-modal-label">
                {this.state.header}
              </h5>
              <Icon type="button" className="btn-close text-white" icon="mdi:close" onClick={this.props.toggleBackupUrlModal} />
            </div>

            <form className="py-3" onSubmit={this.props.handleSubmit}>
              <Slider {...settings} ref={this.sliderRef}>                
                <div>
                  <div className="modal-body">
                    <h6>{t("global.one-click-bookmark")}</h6>
                    <img src={icons["change-link-img.svg"]} alt="change link img" />
                    <hr />

                    <h5 className="modal-title" id="vip-link-modal-label">
                      {t("global.personal-vip-domain")}
                    </h5>
                    <ul>
                      {(() => {
                        (this.props.backupUrlLoading === true && this.state.loading === true) && <Skeleton count={4} height="100%" />;

                        if (this.props.backupUrlLoading === false && this.state.loading === false) {
                          if (this.props.topProfileData && this.props.topProfileData.topProfile.deposit_status === 1) {
                            if (this.state.activeDomain.length > 0) {
                              return (
                                <>
                                  {this.state.activeDomain.map((domain, idx) => (
                                    <>
                                      <li
                                        className={`btn btn-outline-blue ${this.props.backupUrlValue === domain && "active"}`}
                                        onClick={(e) => this.props.redirectBackupUrlHandler(e, domain)}
                                        key={idx}
                                      >
                                        <Icon icon="uil:globe" />
                                        <span>{domain}</span>
                                      </li>
                                    </>
                                  ))}
                                </>
                              )
                            }
                          }
                          else {
                            if (this.state.nonActiveDomain.length > 0) {
                              return (
                                <>
                                  {this.state.nonActiveDomain.map((domain, idx) => (
                                    <>
                                      <li
                                        className={`btn btn-outline-blue ${this.props.backupUrlValue === domain && "active"}`}
                                        onClick={(e) => this.props.redirectBackupUrlHandler(e, domain)}
                                        key={idx}
                                      >
                                        <Icon icon="uil:globe" />
                                        <span>{domain}</span>
                                      </li>
                                    </>
                                  ))}
                                </>
                              )
                            }
                          }
                          // return (
                          //   <>
                          //     <li
                          //       className={`btn btn-outline-blue ${this.props.backupUrlValue === process.env.REACT_APP_PUBLIC_URL && "active"}`}
                          //       onClick={(e) => this.props.redirectBackupUrlHandler(e, process.env.REACT_APP_PUBLIC_URL)}
                          //     >
                          //       <Icon icon="uil:globe" />
                          //       <span>{process.env.REACT_APP_PUBLIC_URL}</span>
                          //     </li>
                          //   </>
                          // );
                        }
                      })()}
                    </ul>
                  </div>
                </div>
              </Slider>
              {this.props.backupUrlLoading === false ? (
                <div className="modal-footer">
                  {/* <button type="button" className="btn btn-gradient-blue" onClick={this.redirectBackupUrlHandler}>
                                      {t("global.confirm")}
                                  </button> */}
                  <div className="d-flex align-items-center w-100 px-3">
                    <input type="checkbox" id="dont-show-check" value="showModal" onChange={(e) => this.props.setDontShow(e)} />
                    <label className="ms-1" htmlFor="dont-show-check">
                      {t("global.dont-ask-again")}
                    </label>
                  </div>
                </div>
              ) : (
                <Skeleton count={1} height="100%" />
              )}
            </form>

          </div>
        )}
      </Modal>

    )
  }
}

const mapStateToProps = (state) => {
  return {
    spare_domain_list: state.spareDomain.spare_domain_list,
    currentUser: state.currentUser.data,
  };
};

const mapDispatchToProps = {
  getSpareDomain
};

export default connect(mapStateToProps, mapDispatchToProps)(withNamespaces("translation")(BackupUrlModal));
