import React, { Component } from 'react';
import Auxiliary from "../../../hoc/Auxiliary/Auxiliary";
import { Icon } from '@iconify/react';
import { withNamespaces } from "react-i18next";
import Swal from "sweetalert2";
import { isDesktop } from "react-device-detect";
import { connect } from "react-redux";
import { claimAngpau } from "../../../store/ducks/angpau/actions";

export class DewaliEvent extends Component {
  constructor(props) {
    super(props);
    this.state = {
      ketupakAmt: "",
      bonusStatus: false,
    };
  }

  componentDidMount() {
    document.body.classList.add('modal-open');
  }
  
  componentDid= () => {
    const { t } = this.props;
    Swal.fire({
      iconHtml: (!isDesktop) ? '<img src="https://api.iconify.design/iconamoon:pensive-face-thin.svg?color=%2393a2Aa">' : '',
      icon: "info",
      title: t("global.sorry"),
      text: t("cny.raya-requirement"),
      confirmButtonText: t("global.ok"),
      customClass: (!isDesktop) ? {
        container: "swal-mobile-container sorry",
        icon: "swal-sorry-icon",
      } : []
    });
  }

  

  shouldComponentUpdate(nextProps, nextState) {
    const { t } = this.props;

    if (nextProps.claim_angpau_result !== this.props.claim_angpau_result) {
      if (nextProps.claim_angpau_result.responseCode === 200) {
          if(nextProps.claim_angpau_result.data.depositStatus === false){
            Swal.fire({
              iconHtml: (!isDesktop) ? '<img src="https://api.iconify.design/iconamoon:pensive-face-thin.svg?color=%2393a2Aa">' : '',
              icon: "info",
              title: t("global.sorry"),
              text: t("cny.raya-requirement"),
              confirmButtonText: t("global.ok"),
              customClass: (!isDesktop) ? {
                container: "swal-mobile-container sorry",
                icon: "swal-sorry-icon",
              } : []
            });
          }else{
            this.setState({
              bonusStatus: true,
              ketupakAmt: nextProps.claim_angpau_result.data.amount 
            });
          }
      } else {
        return false;
      }
    }

    return true;
  }

  clickLantern = () => {
    let data = {};
    data.action = "click";
    this.props.claimAngpau(data);
    // this.setState({
    //   bonusStatus: true,
    // });
  };

  closeDewali = () => {
    this.setState({
      homePageEvents: false,
    });
  };
  

  imageImport = (r) => {
    let images = {};
    r.keys().map((item) => {
      return (images[item.replace("./", "")] = r(item)["default"]);
    });
    return images;
  };

  render() {
    const { t } = this.props;
    const dewali = this.imageImport(require.context("../../../assets/images/home-overlay-events/dewali", false, /\.(png|jpe?g|svg|webp)$/));

    return (
      <Auxiliary>
        {this.props.homePageEvents ? (<>
          <section className='ani-section raya dewali'>
            <div className='ani-content-wrapper'>
              <div className={`ani-header-wrapper ${!isDesktop && 'mobile'}`}>
                <div className='ani-title'>
                        {this.state.bonusStatus ? 
                            <h1>{t("cny.congratz")}</h1> 
                            :
                            <h1>{t("cny.dewali-events")}</h1> 
                        }
                    </div>
                    <h5>{t("cny.highest-receive")} RM168</h5>
               </div>
              <div className='ani-item-wrapper'>
              <img className={`flare-light ${!isDesktop && 'mobile'}`} src={dewali["shine.png"]} alt="flare light" />
              {this.state.bonusStatus === false ? (
                  <>
               <img className={`fes-ani-asset ${!isDesktop && 'mobile'}`} src={dewali["dewali.png"]} alt="dewali" onClick={this.clickLantern} />
               </>
               ) :
                  (
                  <>
                  <div className={`fes-amount-wrap ${!isDesktop && 'mobile'}`}>
                  <p className='fes-ani-amount'>RM {this.state.ketupakAmt}</p>
                </div>
                <img className={`fes-ani-asset ${!isDesktop && 'mobile'}`} src={dewali["dewali.png"]} alt="ketupak open" />
                  </>
                  
                  )
                  
                }
                              <div className={`ani-bottom-wrapper ${!isDesktop && 'mobile'} ${this.state.bonusStatus === false && 'dewali-margin'}` }>
              {this.state.bonusStatus === false &&
               <button className={`claim-btn ${!isDesktop && 'mobile'}`} onClick={this.clickLantern} >{t("cny.receive-bonus")}</button>
              }
              <div className='close-button-wrapper'>
              {this.state.bonusStatus === false &&
                  <h5 onClick={(e) => { e.preventDefault(); window.location.href = '/promos'; }}>{t("cny.details")}</h5>
                }
                <Icon type="button" className="fes-btn-close" icon="mdi:close" onClick={this.props.toggleHomeEvents} />
              </div>
              </div>
              </div>
              <img className={`title-bg lantern-bg ${!isDesktop && 'mobile'}`} src={dewali["dewali_bg.png"]} alt="ketupak-ribbon" />
            </div>
            { isDesktop ? <img className="fes-ani-overlay" src={dewali["raya-bg.png"]} alt="ketupak open" /> : <img className="fes-ani-overlay" src={dewali["raya-bg-mobile.png"]} alt="ketupak open" />}
            
          </section>
        </>
        ) :
          ("")
        }

      </Auxiliary>
    )
  }
}

const mapStateToProps = (state) => {
  return {
    claim_angpau_result: state.events.claim_angpau_result
  };
};

const mapDispatchToProps = {
  claimAngpau
};


export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withNamespaces("translation")(DewaliEvent));