import React, { Component } from "react";
import { Link } from "react-router-dom";
import { isDesktop } from "react-device-detect";
import { connect } from "react-redux";
import Swal from "sweetalert2";
import { withNamespaces } from "react-i18next";

//Miscellaneous
import Auxiliary from "../../hoc/Auxiliary/Auxiliary";

import BackVideo from "../../assets/images/videos/login-page-video-b.mp4";
import { default as ReactSelect } from "react-select";
import MobileForgetPassword from "./MobileForgetPassword";
// Components
import Skeleton from "react-loading-skeleton";
import { formValidation } from "../../hoc/Shared/utility";
import StringInput from "../../components/Input/StringInput/StringInput";
import NumberInput from "../../components/Input/NumberInput/NumberInput";
import PasswordInput from "../../components/Input/PasswordInput/PasswordInput";
// import ValidationError from "../../components/ValidationError/ValidationError";

import { postForgotPasswordEmail, generateForgotPasswordOTP, resendForgotPasswordOTP, verifyForgotPasswordOTP, postForgotPassword, checkForgotPassword, postForgotPasswordOTP } from "../../store/ducks/forgotPassword/actions";
import { generateSMSOTP, verifySMSOTP, resendOTP } from "../../store/ducks/profile/actions";
import { getCountry } from "../../store/ducks/home/actions";
import PageHeader from "../../components/PageHeader/PageHeader";


let countryList = [];
let contact_no_min_limits = 9;
let contact_no_max_limits = 10;

const initialFormInputState = {
    showPwd: false,
    showPwdConfirmation: false,
}

class ForgetPassword extends Component {
    constructor(props) {
        super(props);
        this.state = {
            countryOption: "MY",
            countryPhone: "+60",
            activeBtn: (this.props.match.params.token) ? "change_password" : "select",
            // activeBtn: "change_password",
            showTime: "",
            email: "",
            errorMsg: [],
            formError: false,
            loading: true,
            time: {},
            seconds: 180,
            timer: 0,
            currentMobileNo: "",
            currentMobileDialCode: "",
            otp_code: "",
            showPassword: false,
            formFilter: initialFormInputState,

            oldPassword: "",
            newPassword: "",
            confirmNewPassword: ""
        };
    }

    componentDidMount() {
        
        if(this.props.match.params.token){
            const data = {};
            data.token = this.props.match.params.token;
    
            this.props.checkForgotPassword(data);
        }
       
        formValidation("change-new-pwd-from");
    }

    shouldComponentUpdate(nextProps, nextState) {
        const { t } = this.props;
        if (nextProps.countryData !== this.props.countryData) {
            this.setState({
                referral: sessionStorage.getItem("referralCode"),
            });
            countryList = [];

            let countryData = nextProps.countryData.country;

            Object.keys(countryData).map((item, i) => {
                countryList.push({
                    // label: countryData[item].dialCode + "(" + countryData[item].countryName + ")",
                    name: countryData[item].countryName,
                    value: countryData[item].dialCode,
                    phone: countryData[item].dialCode,
                    code: countryData[item].countryShortCode,
                    image: countryData[item].countryShortCode + ".webp",
                });
                
                return [countryList];
            });

            this.setState({ loading: false });
        }

        if (nextProps.post_forgot_password_email_data !== this.props.post_forgot_password_email_data) {
            const { t } = this.props;
            if (nextProps.post_forgot_password_email_data.responseCode === 200) {
                Swal.fire({
                    iconHtml: (!isDesktop) ? '<img src="https://api.iconify.design/ri:check-fill.svg?color=%2306DB04">' : '',
                    title: t("global.success"),
                    text: t("forgot-password.success"),
                    // icon: nextProps.post_forgot_password_email_data.msgType,
                    icon: 'success',
                    confirmButtonText: t("global.ok"),
                    customClass: (!isDesktop) ? {
                        container: 'swal-mobile-container sucess',
                        icon: 'swal-success-icon'
                    } : [],
                }).then((result) => {
                    if (result.isConfirmed) {
                        this.setState({ showSendSuccessMessage: true });
                    }
                });
            } else if (nextProps.post_forgot_password_email_data.responseCode === 422) {
                this.setState({
                    loading: false,
                    touched: true,
                    errorMsg: nextProps.post_forgot_password_email_data.message.input_error || [],
                    formError: true,
                });
                Swal.close();
            } else {
                this.setState({ loading: false });
                Swal.fire({
                    iconHtml: (!isDesktop) ? '<img src="https://api.iconify.design/iconamoon:pensive-face-thin.svg?color=%2393a2Aa">' : '',
                    title: t("global.sorry"),
                    text: t("forgot-password.error"),
                    // icon: nextProps.post_forgot_password_email_data.data.msgType,
                    icon: "error",
                    confirmButtonText: t("global.ok"),
                    customClass: (!isDesktop) ? {
                        container: 'swal-mobile-container sorry',
                        icon: 'swal-sorry-icon'
                    } : [],
                });
            }
        }

        if (nextProps.generate_forgot_password_otp_data !== this.props.generate_forgot_password_otp_data) {
            if (nextProps.generate_forgot_password_otp_data.responseCode === 200) {
                if (!nextProps.generate_forgot_password_otp_data.data.sms_reached_limit) {
                    let timeLeftVar = this.secondsToTime(this.state.seconds);

                    this.setState({ time: timeLeftVar });

                    this.startTimer();
                }

                this.setState({
                    generateOTPStatus: true,
                    check_sending_sms_times: nextProps.generate_forgot_password_otp_data.data.count_sending_sms_times,
                    check_sending_sms_status: nextProps.generate_forgot_password_otp_data.data.sms_reached_limit === true ? false : true,
                    resend_otp_button_disabled: true,
                });

                if (!nextProps.generate_forgot_password_otp_data.data.sms_reached_limit) {
                Swal.fire({
                    iconHtml: (!isDesktop) ? '<img src="https://api.iconify.design/ri:check-fill.svg?color=%2306DB04">' : '',
                    // icon: nextProps.generate_forgot_password_otp_data.msgType,
                    icon: 'success',
                    title: t("global.success"),
                    // text: "Successful request OTP",
                    html: t("profile.generate-sms-otp-success"),
                    showConfirmButton: true,
                    confirmButtonText: t("global.ok"),
                    customClass: (!isDesktop) ? {
                        container: 'swal-mobile-container sucess',
                        icon: 'swal-success-icon'
                    } : [],
                }).then((result) => {
                    if (result.isConfirmed) {
                        Swal.close();
                    }
                });
            }

            } else {
                this.setState({
                    touched: true,
                    errorMsg: (nextProps.generate_forgot_password_otp_data.data.message.input_error || []),
                    formError: true,
                });
                
                // Swal.close();
            }
        }

        if (nextProps.resend_forgot_password_otp_data !== this.props.resend_forgot_password_otp_data) {
            if (nextProps.resend_forgot_password_otp_data.responseCode === 200) {
                if (!nextProps.resend_forgot_password_otp_data.data.sms_reached_limit) {
                    this.setState({ check_sending_sms_status: false });
                    Swal.close();
                } else {
                Swal.fire({
                    iconHtml: (!isDesktop) ? '<img src="https://api.iconify.design/ri:check-fill.svg?color=%2306DB04">' : '',
                    // icon: nextProps.resend_forgot_password_otp_data.msgType,
                    icon: 'success',
                    title: t("global.success"),
                    //text: press second time request will get this message
                    html: t("profile.generate-sms-otp-success"),
                    showConfirmButton: true,
                    confirmButtonText: t("global.ok"),
                    customClass: (!isDesktop) ? {
                        container: 'swal-mobile-container sucess',
                        icon: 'swal-success-icon'
                    } : [],
                }).then(() => {
                    this.setState({
                        generateOTPStatus: true,
                        openMobileModal: true,
                        check_sending_sms_times: nextProps.resend_forgot_password_otp_data.data.count_sending_sms_times,
                        seconds: 180,
                        timer: 0,
                        resend_otp_button_disabled: true,
                    });
                });
                }
            } else {
                this.setState({
                    touched: true,
                    errorMsg: nextProps.resend_forgot_password_otp_data.data.message.input_error || [],
                    formError: true,
                    resend_otp_button_disabled: true,
                });
                Swal.close();
            }
        }

        if (nextProps.verify_forgot_password_otp_data !== this.props.verify_forgot_password_otp_data) {
            if (nextProps.verify_forgot_password_otp_data.responseCode === 200) {
                Swal.fire({
                    iconHtml: (!isDesktop) ? '<img src="https://api.iconify.design/ri:check-fill.svg?color=%2306DB04">' : '',
                    // icon: nextProps.verify_forgot_password_otp_data.msgType,
                    icon: 'success',
                    title: t("global.success"),
                    html: t("forgot-password.forgot-password-pin"),
                    showConfirmButton: true,
                    confirmButtonText: t("global.ok"),
                    customClass: (!isDesktop) ? {
                        container: 'swal-mobile-container sucess',
                        icon: 'swal-success-icon'
                    } : [],
                }).then((result) => {
                    if (result.isConfirmed) {
                        Swal.close();
                    }
                    this.setState({ activeBtn: "change_password" });
                });
                // const data = this.props.currentUser;
                // data.contact_no = this.state.currentMobileDialCode + this.state.currentMobileNo;

                // // here is that the goup id problem
                // this.props.getUserData(data);

                // this.props.getTopProfile();
                // this.props.getMemberProfile();

                clearInterval(this.timer);
            } else {
                this.setState({
                    touched: true,
                    errorMsg: (nextProps.verify_forgot_password_otp_data.message.input_error || []),
                    formError: true
                });
                Swal.close();
            }
        }

        if (nextProps.check_forgot_password_data !== this.props.check_forgot_password_data) {
            if (nextProps.check_forgot_password_data.responseCode === 200) {
               this.props.history.push("/verify-forgot-password/"+ this.props.match.params.token)
            }else{
                Swal.fire({
                    iconHtml: (!isDesktop) ? '<img src="https://api.iconify.design/ri:check-fill.svg?color=%2306DB04">' : '',
                    title: t("global.sorry"),
                    text: nextProps.check_forgot_password_data.data.message,
                    // icon: nextProps.check_forgot_password_data.data.msgType,
                    icon: 'error',
                    confirmButtonText: t("global.ok"),
                    customClass: (!isDesktop) ? {
                        container: 'swal-mobile-container sucess',
                        icon: 'swal-success-icon'
                    } : [],
                }).then((result) => {
                    if (result.isConfirmed) {
                        this.props.history.push("/");
                    }
                });
            }
        }

        if (nextProps.post_forgot_password_data !== this.props.post_forgot_password_data) {
            if (nextProps.post_forgot_password_data.responseCode === 200) {
                Swal.fire({
                    iconHtml: (!isDesktop) ? '<img src="https://api.iconify.design/ri:check-fill.svg?color=%2306DB04">' : '',
                    title: t("global.success"),
                    text: nextProps.post_forgot_password_data.message,
                    // icon: nextProps.post_forgot_password_data.msgType,
                    icon: 'success',
                    confirmButtonText: t("global.ok"),
                    customClass: (!isDesktop) ? {
                        container: 'swal-mobile-container sucess',
                        icon: 'swal-success-icon'
                    } : [],
                }).then((result) => {
                    if (result.isConfirmed) {
                        this.props.history.push("/");
                    }
                });
            } else if (nextProps.post_forgot_password_data.responseCode === 422) {
                this.setState({
                    loading: false,
                    touched: true,
                    error_message: nextProps.post_forgot_password_data.message,
                });
                Swal.close();
            } else {
                this.setState({ loading: false });
                Swal.fire({
                    iconHtml: (!isDesktop) ? '<img src="https://api.iconify.design/mdi:close.svg?color=%23ea4e3d">' : '',
                    title: t("global.failed"),
                    text: nextProps.post_forgot_password_data.data.message[0],
                    // icon: nextProps.post_forgot_password_data.data.msgType,
                    icon: "error",
                    confirmButtonText: t("global.ok"),
                    customClass: (!isDesktop) ? {
                        container: "swal-mobile-container failed",
                        icon: "swal-failed-icon", 
                    } : [],
                });
            }
        }

        if (nextProps.post_forgot_password_otp_data !== this.props.post_forgot_password_otp_data) {
            if (nextProps.post_forgot_password_otp_data.responseCode === 200) {
                Swal.fire({
                    iconHtml: (!isDesktop) ? '<img src="https://api.iconify.design/ri:check-fill.svg?color=%2306DB04">' : '',
                    title: t("global.success"),
                    text: nextProps.post_forgot_password_otp_data.message,
                    // icon: nextProps.post_forgot_password_otp_data.msgType,
                    icon: 'success',
                    confirmButtonText: t("global.ok"),
                    customClass: (!isDesktop) ? {
                        container: 'swal-mobile-container sucess',
                        icon: 'swal-success-icon'
                    } : [],
                }).then((result) => {
                    if (result.isConfirmed) {
                        this.props.history.push("/");
                    }
                });
            } else if (nextProps.post_forgot_password_otp_data.responseCode === 422) {
                this.setState({
                    loading: false,
                    touched: true,
                    error_message: nextProps.post_forgot_password_otp_data.message,
                });
                Swal.close();
            
            } else {
                this.setState({ loading: false });
                Swal.fire({
                    iconHtml: (!isDesktop) ? '<img src="https://api.iconify.design/mdi:close.svg?color=%23ea4e3d">' : '',
                    title: t("global.failed"),
                    text: nextProps.post_forgot_password_otp_data.data.message[0],
                    // icon: nextProps.post_forgot_password_otp_data.data.msgType,
                    icon: "error",
                    confirmButtonText: t("global.ok"),
                    customClass: (!isDesktop) ? {
                        container: "swal-mobile-container failed",
                        icon: "swal-failed-icon", 
                    } : [],
                });
            }
        }

    
        return true;
    }

    changeActiveBtn = (value) => {
        this.setState({
            activeBtn: value,
        }, () => {
            if(value === "email"){
                formValidation("forgot_password_form");
            }
            if(value === "phone"){
                formValidation("phone_forgot_password_form");
            }
        });

        if (value === "phone") {
            this.props.getCountry();
        }
    };

    showPwd = () => {
        let newFilter = Object.assign({}, this.state.formFilter);
        newFilter["showPwd"] = !this.state.formFilter.showPwd;
        this.setState({
            formFilter: newFilter,
        });
    };

    showPwdConfirmation = () => {
        let newFilter = Object.assign({}, this.state.formFilter);
        newFilter["showPwdConfirmation"] = !this.state.formFilter.showPwdConfirmation;
        this.setState({
            formFilter: newFilter,
        });
    };

    selectCountry = (event) => {
        this.setState({
            countryOption: event.code,
            countryPhone: event.phone,
        });
    };

    currentMobileNoFunction = (e) => {
        this.setState({
            currentMobileNo: e.target.value,
        });
    };

    otp_code_Function = (e) => {
        this.setState({
            otp_code: e.target.value,
        });
    };

    secondsToTime(secs) {
        let hours = Math.floor(secs / (60 * 60));

        let divisor_for_minutes = secs % (60 * 60);
        let minutes = Math.floor(divisor_for_minutes / 60);

        let divisor_for_seconds = divisor_for_minutes % 60;
        let seconds = Math.ceil(divisor_for_seconds);

        let obj = {
            h: hours,
            m: minutes,
            s: seconds.toString().padStart(2, "0"),
        };
        return obj;
    }

    startTimer() {
        if (this.state.timer === 0 && this.state.seconds > 0) {
            this.timer = setInterval(this.countDown, 1000);
            this.setState({ showTime: true });
        }
    }

    countDown = () => {
        // Remove one second, set state so a re-render happens.
        let seconds = this.state.seconds - 1;

        this.setState({
            time: this.secondsToTime(seconds),
            seconds: seconds,
        });

        // Check if we're at zero.
        if (seconds === 0) {
            clearInterval(this.timer);
            this.setState({ resend_otp_button_disabled: false, disabled_resend_contact_no: false, showTime: false });
        }
    };

    imageImport = (r) => {
        let images = {};
        r.keys().map((item, index) => {
            return (images[item.replace("./", "")] = r(item)["default"]);
        });
        return images;
    };

    submitForgotPasswordEmailHandler = (event) => {
        event.preventDefault();

        const { t } = this.props;

        Swal.fire({
            iconHtml: (!isDesktop) ? '<img src="https://api.iconify.design/ic:outline-question-mark.svg?color=%2302f1ff">' : '',
            title: t("global.confirmation"),
            icon: "warning",
            text: t("global.would-you-like-to-proceed") + "?",
            confirmButtonText: t("global.confirm"),
            showDenyButton: true,
            denyButtonText: t("global.cancel"),
            customClass: (!isDesktop) ? {
                container: "swal-mobile-container confirmation",
                icon: "swal-confirmation-icon",
            } : [],
        }).then((result) => {
            if (result.isConfirmed) {
                Swal.fire({
                    title: "",
                    allowOutsideClick: false,
                    allowEscapeKey: false,
                    allowEnterKey: false,
                    onOpen: () => {
                        Swal.showLoading();
                    },
                });

                const data = {};

                data.email = this.state.email;
                data.language_code = this.props.currentUser.language_code;
                data.url = window.location.origin;

                this.props.postForgotPasswordEmail(data);
            }
        });
    };

    handleEmail = (event) => {
        this.setState({ email: event.target.value });
    };

    generateOTPHandler = (event) => {
        event.preventDefault();
        const data = {};

        data.contact_number = this.state.countryPhone + this.state.currentMobileNo;
        data.contact_prefix = this.state.countryPhone;

        Swal.fire({
            allowOutsideClick: false,
            allowEscapeKey: false,
            allowEnterKey: false,
            didOpen: () => {
                Swal.showLoading();
            },
        });

        this.props.generateForgotPasswordOTP(data);
    };

    resendOTPHandler = (event) => {
        event.preventDefault();

        const { t } = this.props;
        Swal.fire({
            iconHtml: (!isDesktop) ? '<img src="https://api.iconify.design/ic:outline-question-mark.svg?color=%2302f1ff">' : '',
            title: t("global.confirmation"),
            text: t("global.would-you-like-to-proceed") + "?",
            icon: "warning",
            confirmButtonText: t("global.confirm"),
            showDenyButton: true,
            denyButtonText: t("global.cancel"),
            customClass: (!isDesktop) ? {
                container: "swal-mobile-container confirmation",
                icon: "swal-confirmation-icon", 
            } : [],
        }).then((result) => {
            if (result.isConfirmed) {
                Swal.fire({
                    allowOutsideClick: false,
                    allowEscapeKey: false,
                    allowEnterKey: false,
                    didOpen: () => {
                        Swal.showLoading();
                    },
                });

                const data = {};

                data.contact_number = this.state.countryPhone + this.state.currentMobileNo;

                data.contact_prefix = this.state.countryPhone;

                this.props.resendForgotPasswordOTP(data);

                this.setState({ seconds: 180 });

                let timeLeftVar = this.secondsToTime(this.state.seconds);

                this.setState({ time: timeLeftVar });

                this.startTimer();
            }
        });
    };

    verifyForgotPasswordOTPHandler = (event) => {
        event.preventDefault();

        const { t } = this.props;

        Swal.fire({
            iconHtml: (!isDesktop) ? '<img src="https://api.iconify.design/ic:outline-question-mark.svg?color=%2302f1ff">' : '',
            title: t("global.confirmation"),
            text: t("global.would-you-like-to-proceed") + "?",
            icon: "warning",
            confirmButtonText: t("global.confirm"),
            showDenyButton: true,
            denyButtonText: t("global.cancel"),
            customClass: (!isDesktop) ? {
                container: "swal-mobile-container confirmation",
                icon: "swal-confirmation-icon",
            } : [],
        }).then((result) => {
            if (result.isConfirmed) {
                Swal.fire({
                    allowOutsideClick: false,
                    allowEscapeKey: false,
                    allowEnterKey: false,
                    didOpen: () => {
                        Swal.showLoading();
                    },
                });

                const data = {};

                // if (this.state.resend_contact_no !== "") {
                // data.contact_no = this.state.currentMobileDialCode + this.state.resend_contact_no;
                // } else {
                data.contact_number = this.state.countryPhone + this.state.currentMobileNo;
                // }

                data.otp_code = this.state.otp_code;

                this.props.verifyForgotPasswordOTP(data);
            }
        });
    }

    submitForgotPasswordHandler = (event) => {
        event.preventDefault();

        const { t } = this.props;

        Swal.fire({
            iconHtml: (!isDesktop) ? '<img src="https://api.iconify.design/ic:outline-question-mark.svg?color=%2302f1ff">' : '',
            title: t("global.confirmation"),
            text: t("global.would-you-like-to-proceed") + "?",
            icon: "warning",
            confirmButtonText: t("global.confirm"),
            showDenyButton: true,
            denyButtonText: t("global.cancel"),
            customClass: (!isDesktop) ? {
                container: "swal-mobile-container confirmation",
                icon: "swal-confirmation-icon",
            } : [],
        }).then((result) => {
            if (result.isConfirmed) {
                Swal.fire({
                    title: "",
                    allowOutsideClick: false,
                    allowEscapeKey: false,
                    allowEnterKey: false,
                    onOpen: () => {
                        Swal.showLoading();
                    },
                });
        
                const data = {};

                data.token = this.props.match.params.token;
                data.password = this.state.newPassword;
                data.confirm_password = this.state.confirmNewPassword;

                this.props.postForgotPassword(data);
            }
        });
    }

    submitForgotPasswordOTPHandler = (event) => {
        event.preventDefault();

        const { t } = this.props;
        
        Swal.fire({
            iconHtml: (!isDesktop) ? '<img src="https://api.iconify.design/ic:outline-question-mark.svg?color=%2302f1ff">' : '',
            title: t("global.confirmation"),
            text: t("global.would-you-like-to-proceed") + "?",
            icon: "warning",
            confirmButtonText: t("global.confirm"),
            showDenyButton: true,
            denyButtonText: t("global.cancel"),
            customClass: (!isDesktop) ? {
                container: "swal-mobile-container confirmation",
                icon: "swal-confirmation-icon",
            } : [],
        }).then((result) => {
            if (result.isConfirmed) {
                Swal.fire({
                    title: "",
                    allowOutsideClick: false,
                    allowEscapeKey: false,
                    allowEnterKey: false,
                    onOpen: () => {
                        Swal.showLoading();
                    },
                });
        
                const data = {};

                data.contact_number = this.state.countryPhone.substring(1) + this.state.currentMobileNo;
                data.password = this.state.newPassword;
                data.confirm_password = this.state.confirmNewPassword;

                this.props.postForgotPasswordOTP(data);
            }
        });
    }

    onChangeNewPasswordHandler = (e) => {
        this.setState({
            newPassword: e.target.value
        })
    }

    onChangeConfirmNewPasswordHandler = (e) => {
        this.setState({
            confirmNewPassword: e.target.value
        })
    }

    render() {
        const { t } = this.props;
        const img = this.imageImport(require.context("../../assets/images/register/", false, /\.(png|jpe?g|svg|webp)$/));
        const flag = this.imageImport(require.context("../../assets/images/flags/", false, /\.(png|jpe?g|svg|webp)$/));

        switch (this.state.countryOption) {
            case "MY":
                contact_no_min_limits = 9;
                contact_no_max_limits = 13;
                break;
            case "TH":
                contact_no_min_limits = 8;
                contact_no_max_limits = 8;
                break;
            case "ID":
                contact_no_min_limits = 11;
                contact_no_max_limits = 14;
                break;
            case "VN":
                contact_no_min_limits = 9;
                contact_no_max_limits = 9;
                break;
            case "7":
                contact_no_min_limits = 10;
                contact_no_max_limits = 10;
                break;
            default:
                break;
        }

        let resendOTPCountDownTimer;

        if (this.state.resend_otp_button_disabled === true) {
            resendOTPCountDownTimer = "0" + this.state.time.m + ":" + this.state.time.s;
        } else {
            resendOTPCountDownTimer = "Request";
        }

        if(this.state.activeBtn === "email"){
            formValidation("forgot_password_form");
        }

        let imageLanguage = '';
        switch (this.props.currentUser.language_code) {
            case "zh-CN":
                imageLanguage = "-cn";
                break;
            case "ms":
                imageLanguage = "-bm"
                break;
            case "indon":
                imageLanguage = "-bm"
                break;
            default:
                imageLanguage = "";
                break;
        }
        

        return (
            <Auxiliary>
                {isDesktop ? (
                    <>
                        {/* <video className="login-video" autoPlay loop muted >
                            <source src={BackVideo} type="video/mp4" />
                        </video> */}

                        <section className="register-section">
                            <div className="x-container-no-m">
                                <div className="forget-password-wrap">
                                    <div className="forget-password-content">                                        

                                        {/* If need back btn, use back this */}
                                        {/* {this.state.activeBtn === "change_password" ? (
                                            <small onClick={() => this.changeActiveBtn("select")}>{" "}{t("global.back")}</small>                                           
                                        ) : (
                                            <Link to={{ pathname: "/login" }}>
                                                <small> {t("global.back")} </small>{" "}
                                            </Link>
                                        )} */}

                                        {this.state.activeBtn !== "change_password" && (
                                            <Link to={{ pathname: "/login" }}>
                                                <small> {t("global.back")} </small>{" "}
                                            </Link>                                      
                                        )}

                                        {this.state.activeBtn !== "change_password" &&  (
                                            <div>
                                                <div className="mt-4">
                                                    <h4>{t("forgot-password.title")} </h4>
                                                    <p>{t("forgot-password.desc")}</p>
                                                </div>
                                                <div className="forget-pwd-btn">
                                                    <button type="button" onClick={() => this.changeActiveBtn("email")} className={this.state.activeBtn === "email" ? "active" : ""}>
                                                        {/* <img src={img["icon-login-email.webp"]} alt="forget pwd email" /> */}
                                                        {t('profile.email-address')}
                                                    </button>
                                                    {/* <span style={{ color: "$slate-gray-200" }}>{t("page-header.or")}</span> */}
                                                    <button type="button" onClick={() => this.changeActiveBtn("phone")} className={this.state.activeBtn === "phone" ? "active" : ""}>
                                                        {/* <img src={img["icon-forget-phone.webp"]} alt="forget pwd phone" /> */}
                                                        {t('forgot-password.mobile-phone')}
                                                    </button>
                                                </div>
                                            </div>
                                        )}


                                        {this.state.activeBtn === "email" ? (
                                            <div>
                                                {/* <div>
                                                    <h4>{t("forgot-password.title")} </h4>
                                                    <p>{t("forgot-password.desc")}</p>
                                                </div> */}                                                              
                                                <form
                                                    name="forgot_password_form"
                                                    id="forgot_password_form"
                                                    onSubmit={this.submitForgotPasswordEmailHandler}
                                                    className="forgot_password_form password-form needs-validation"
                                                    noValidate
                                                    method="post"
                                                >
                                                    <div>    
                                                        <label className="col-form-label lh-1 p-0">{t('forgot-password.enter-email-address')}</label>                                                    
                                                        <div className="input-group register-global-input no-margin">                                                                      

                                                            <span className="input-group-text left-icon">
                                                                <img src={img["icon-login-email.webp"]} alt="forget pwd email" />
                                                            </span>

                                                            <StringInput
                                                                className={`${this.state.errorMsg.email && "was-validated is-invalid"}`}
                                                                onChange={this.handleEmail}
                                                                id="email"
                                                                name="email"
                                                                placeholder={t('profile.email-address')}
                                                                value={this.state.email}
                                                                required
                                                                valid_email="true"
                                                                requiredMessage={t("validation.required")}
                                                                emailMessage={t("validation.email")}
                                                                errorMsg={this.state.errorMsg.email || ""}
                                                                formError={this.state.formError}
                                                            />
                                                        </div>
                                                    </div>
                                                </form>
                                            </div>                                            
                                        ) : this.state.activeBtn === "phone" ? (
                                            <div>
                                                {/* <div>
                                                    <h4>{t("forgot-password.title")} </h4>
                                                    <p>{t("forgot-password.desc")}</p>
                                                </div> */}                                               
                                                <form name="phone_forgot_password_form" id="phone_forgot_password_form" method="post" className="password-form reg-and-log-select" onSubmit={this.verifyForgotPasswordOTPHandler} noValidate>
                                                        {this.state.loading ? (
                                                            <Skeleton count={2} height={40} style={{ marginBottom: "1rem" }} />
                                                        ) : (
                                                        <>        
                                                            <label className="col-form-label lh-1 p-0">{t('forgot-password.enter-mobile-phone-no')}</label>                                                    
                                                            <div className="input-group position-relative no-margin">                                                                 
                                                                <ReactSelect
                                                                    classNamePrefix="react-select"
                                                                    defaultValue={countryList.filter((country) => country.code === "MY")}
                                                                    options={countryList}
                                                                    onChange={this.selectCountry}
                                                                    formatOptionLabel={(item) => (
                                                                        <>
                                                                            <img src={flag[`${item.image}`]} alt={item.image} />
                                                                            <p>{item.phone}</p>
                                                                            <p>{item.name}</p>
                                                                        </>
                                                                    )}
                                                                />
                                                                <StringInput
                                                                    className={`${this.state.errorMsg.contact_no && "was-validated is-invalid"}`}
                                                                    // style={{width:"70%"}}
                                                                    type="text"
                                                                    placeholder={t("forgot-password.mobile-phone-no")}
                                                                    name="verificationPhoneNo"
                                                                    defaultValue=""
                                                                    value={this.state.currentMobileNo}
                                                                    onChange={this.currentMobileNoFunction}
                                                                    // onChange={(e) => {
                                                                    //     this.setState({
                                                                    //       currentMobileNo: e.target.value,
                                                                    //     });
                                                                    //   }}
                                                                    minLength={contact_no_min_limits}
                                                                    maxLength={contact_no_max_limits}
                                                                    minLengthMessage={t("validation.minLength", { 0: contact_no_min_limits })}
                                                                    maxLengthMessage={t("validation.maxLength", { 0: contact_no_max_limits })}
                                                                    required
                                                                    requiredMessage={t("validation.required")}
                                                                    errorMsg={this.state.errorMsg.contact_no || ""}
                                                                    formError={this.state.formError}
                                                                />
                                                            </div>

                                                            <div className="position-relative">
                                                                <label className="col-form-label lh-1 p-0">{t('forgot-password.insert-otp')}</label>                                                    
                                                                <div className="input-group register-global-input no-margin">                                                       
                                                                    <span className="input-group-text left-icon">
                                                                        <img
                                                                        src={img["icon-password.webp"]}
                                                                        alt="password icon"
                                                                        />
                                                                    </span>
                                                                    {/* <span className="input-group-text left-icon left-text">{t("forgot-password.insert-otp")}</span> */}
                                                                    <NumberInput
                                                                        className={`form-control forget-pwd-desktop ${this.state.errorMsg.otp_code && "was-validated is-invalid"}`}
                                                                        type="number"
                                                                        onChange={this.otp_code_Function}
                                                                        // onChange={(e) => {
                                                                        //   this.setState({
                                                                        //     otp_code: e.target.value,
                                                                        //   });
                                                                        // }}
                                                                        placeholder={t("forgot-password.insert-otp")}
                                                                        errorMsg={this.state.errorMsg.otp_code || ""}
                                                                        formError={this.state.formError}
                                                                        required
                                                                        requiredMessage={t("validation.required")}
                                                                        value={this.state.otp_code}
                                                                        format={
                                                                            <span className="input-group-text right-btn-bg ">
                                                                                {this.state.generateOTPStatus && this.state.check_sending_sms_status ? (
                                                                                    <button
                                                                                        type="button"
                                                                                        className={`btn right-btn  ${this.state.showTime ? "disabled" : "active"}`}
                                                                                        onClick={this.resendOTPHandler}
                                                                                    >
                                                                                        <span>{t("global.resend")}</span>
                                                                                    </button>
                                                                                ) : (
                                                                                    <button
                                                                                        type="button"
                                                                                        className={`btn right-btn  ${this.state.showTime ? "disabled" : "active"}`}
                                                                                        onClick={this.generateOTPHandler}
                                                                                    >
                                                                                        <span>{t("forgot-password.request")}</span>
                                                                                    </button>
                                                                                )}
                                                                            </span>
                                                                        }
                                                                    />
                                                                    {/* <input
                                                                        type="otp_code"
                                                                        id="forgetEmail"
                                                                        className="form-control "
                                                                        name="email"
                                                                        placeholder=""
                                                                        autoComplete="off"
                                                                        required
                                                                    /> */}

                                                                </div>
                                                                {this.state.showTime ? <span className="count">{resendOTPCountDownTimer}</span> : ""}
                                                            </div>
                                                        </>
                                                    )}
                                                </form>
                                            </div>
                                        ) : this.state.activeBtn === "change_password" && (
                                            <form method="post" id="change-new-pwd-from" name="change-new-pwd-from" className="password-form" onSubmit={ !this.props.match.params.token  ? this.submitForgotPasswordOTPHandler : this.submitForgotPasswordHandler} noValidate>
                                                <h4>
                                                    {t('change-password.change-new-password')}
                                                </h4>

                                                <label className="col-form-label lh-1 p-0 margin-top">{t('change-password.new-password')}</label>                                                    

                                                <div className="input-group no-margin">                                                                                          

                                                    <span className="input-group-text left-icon">
                                                        <img src={img["icon-password.webp"]} alt="password icon" />
                                                    </span>

                                                    <PasswordInput
                                                        showPwd={this.state.formFilter.showPwd}
                                                        onChange={this.onChangeNewPasswordHandler}
                                                        id="password"
                                                        name="password"
                                                        className={`no-border form-control ${this.state.errorMsg.newPassword && 'was-validated is-invalid'}`}
                                                        placeholder={t('change-password.enter-new-password')}
                                                        value={this.state.newPassword}
                                                        minLength={6}
                                                        required
                                                        requiredMessage={t("validation.required")}
                                                        minLengthMessage={t("validation.minLength", { 0: 6 })}
                                                        errorMsg={this.state.errorMsg.newPassword || ''}
                                                        formError={this.state.formError}
                                                        format = {
                                                            <span className="input-group-text">
                                                                <button
                                                                    type="button"
                                                                    className=" right-icon"
                                                                    onClick={this.showPwd}
                                                                >
                                                                    {this.state.formFilter.showPwd ? (
                                                                        <img src={img["icon-eye-open.webp"]} alt="show pwd" />
                                                                    ) : (
                                                                        <img src={img["icon-eye-close.webp"]} alt="hide pwd" />
                                                                    )}
                                                                </button>
                                                            </span>
                                                            }
                                                    />
                                                    
                                                </div>

                                                <label className="col-form-label lh-1 p-0">{t('change-password.reenter-new-password')}</label>                                                    
                                                
                                                <div className="input-group no-margin">                                                    

                                                    <span className="input-group-text left-icon">
                                                        <img src={img["icon-password.webp"]} alt="password icon" />
                                                    </span>

                                                    <PasswordInput
                                                        showPwdConfirmation={this.state.formFilter.showPwdConfirmation}
                                                        onChange={this.onChangeConfirmNewPasswordHandler}
                                                        id="password"
                                                        name="password"
                                                        className={`no-border form-control ${this.state.errorMsg.confirmNewPassword && 'was-validated is-invalid'}`}
                                                        placeholder={t('change-password.reenter-new-password')}
                                                        value={this.state.confirmNewPassword}
                                                        minLength={6}
                                                        required
                                                        requiredMessage={t("validation.required")}
                                                        minLengthMessage={t("validation.minLength", { 0: 6 })}
                                                        errorMsg={this.state.errorMsg.confirmNewPassword || ''}
                                                        formError={this.state.formError}
                                                        format = { 
                                                            <span className="input-group-text">
                                                                <button
                                                                    type="button"
                                                                    className=" right-icon"
                                                                    onClick={this.showPwdConfirmation}
                                                                >
                                                                    {this.state.formFilter.showPwdConfirmation? (
                                                                        <img src={img["icon-eye-open.webp"]} alt="show pwd" />
                                                                    ) : (
                                                                        <img src={img["icon-eye-close.webp"]} alt="hide pwd" />
                                                                    )}
                                                                </button>
                                                            </span>
                                                            }
                                                    />
                                                </div>
                                            </form>
                                        )}
                                        {/* className="btn btn-blue desktop"  */}
                                        <button className={`btn btn-blue desktop  ${this.state.activeBtn !== "select" ? "active" : "disabled"}`} type="submit" form={this.state.activeBtn === "change_password" ? "change-new-pwd-from" : (this.state.activeBtn) === "phone" ? "phone_forgot_password_form" : "forgot_password_form"} value="Submit">
                                            {t("global.submit")}
                                        </button>
                                    </div>                                    
                                </div>

                                <div className="jackpot">
                                    <img 
                                        src={img[`jackpot${imageLanguage}.webp`]}
                                        alt="jackpot"
                                    />
                                </div>    
                            </div>

                        </section>
                    </>
                ) : (
                    < MobileForgetPassword
                        changeActiveBtn={this.changeActiveBtn}
                        submitForgotPasswordEmailHandler={this.submitForgotPasswordEmailHandler}
                        verifyForgotPasswordOTPHandler={this.verifyForgotPasswordOTPHandler}
                        selectCountry={this.selectCountry}
                        handleEmail={this.handleEmail}
                        resendOTPHandler={this.resendOTPHandler}
                        generateOTPHandler={this.generateOTPHandler}
                        countryList={countryList}
                        onChangeNewPasswordHandler={this.onChangeNewPasswordHandler}
                        onChangeConfirmNewPasswordHandler={this.onChangeConfirmNewPasswordHandler}
                        submitForgotPasswordOTPHandler={this.submitForgotPasswordOTPHandler}
                        submitForgotPasswordHandler={this.submitForgotPasswordHandler}
                        showPwd={this.showPwd}
                        showPwdConfirmation={this.showPwdConfirmation}
                        otp_code_Function={this.otp_code_Function}
                        currentMobileNoFunction={this.currentMobileNoFunction}

                        {...this.state}
                        {...this.props}
                    />
                )}
            </Auxiliary>
        );
    }
}

//Received the response from the redux calling the api response
const mapStateToProps = (state) => {
    const { forgotPassword, profile } = state;

    return {
        post_forgot_password_email_data: forgotPassword.post_forgot_password_email_data,
        currentUser: state.currentUser.data,
        generate_sms_otp_result: profile.generate_sms_otp_result,
        verify_sms_otp_result: profile.verify_sms_otp_result,
        resend_otp_result: state.profile.resend_otp_result,
        countryData: state.home.country_data,
        generate_forgot_password_otp_data: forgotPassword.generate_forgot_password_otp_data,
        resend_forgot_password_otp_data: forgotPassword.resend_forgot_password_otp_data,
        verify_forgot_password_otp_data: forgotPassword.verify_forgot_password_otp_data,
        post_forgot_password_data: forgotPassword.post_forgot_password_data,
        check_forgot_password_data: forgotPassword.check_forgot_password_data,
        post_forgot_password_otp_data: forgotPassword.post_forgot_password_otp_data
    };
};

//Using the redux calling api methods
const mapDispatchToProps = {
    postForgotPasswordEmail,
    generateSMSOTP,
    verifySMSOTP,
    resendOTP,
    getCountry,
    generateForgotPasswordOTP,
    resendForgotPasswordOTP,
    verifyForgotPasswordOTP,
    checkForgotPassword,
    postForgotPassword,
    postForgotPasswordOTP
};

export default connect(mapStateToProps, mapDispatchToProps)(withNamespaces("translation")(ForgetPassword));
