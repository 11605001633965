import React, { Component } from "react";
import { NavLink } from "react-router-dom";
import { withNamespaces } from "react-i18next";
//Miscellaneous
import Auxiliary from "../../hoc/Auxiliary/Auxiliary";
import domain from "../../common/data/domain.json";
import { connect } from "react-redux";
import { getUserData } from "../../store/ducks/currentUser/actions";

let dynamicCountry = "malaysia";
if (domain.id.key.includes(window.location.host)) {
  dynamicCountry = "indonesia";
}

class MobileBottomNav extends Component {
  constructor(props) {
    super(props);
    this.state = {
      // login: localStorage.getItem("accessToken") ? true : false, // bug here, state not updated as expected

      currentEvent: "cny",
    };
  }

  imageImport = (r) => {
    let images = {};
    r.keys().map((item, index) => {
      return (images[item.replace("./", "")] = r(item)["default"]);
    });
    return images;
  };


  render() {
    const { t } = this.props;
    const img = this.imageImport(
      require.context(
        "../../assets/images/mobile-bottom-nav/",
        false,
        /\.(png|jpe?g|svg|webp)$/
      )
    );
    const vipIcon = this.imageImport(
      require.context(
        "../../assets/images/mobile-page-header/",
        false,
        /\.(png|jpe?g|svg|webp)$/
      )
    );
    const christmas = this.imageImport(
      require.context(
        "../../assets/images/home/christmas",
        false,
        /\.(png|jpe?g|svg|webp)$/
      )
    );
    const chinese = this.imageImport(
      require.context(
        "../../assets/images/home/chinese",
        false,
        /\.(png|jpe?g|svg|webp)$/
      )
    );

    const raya = this.imageImport(
      require.context(
        "../../assets/images/home/raya",
        false,
        /\.(png|jpe?g|svg|webp)$/
      )
    );

    const merdeka = this.imageImport(
      require.context(
        "../../assets/images/home/merdeka",
        false,
        /\.(png|jpe?g|svg|webp)$/
      )
    );

    const mooncake = this.imageImport(
      require.context(
        "../../assets/images/home/mooncake",
        false,
        /\.(png|jpe?g|svg|webp)$/
      )
    );

    const halloween = this.imageImport(
      require.context(
        "../../assets/images/home/halloween",
        false,
        /\.(png|jpe?g|svg|webp)$/
      )
    );

    return (
      <Auxiliary>
        <section className={`bottom-nav-section ${this.state.currentEvent === "mooncake"? "mooncake" :this.state.currentEvent === "cny"? "cny" :  this.state.currentEvent === "christmas" && "christmas"}`}>
          <ul className="bottom-nav-content">
            <li>
              <NavLink
                to={{ pathname: "/promos" }}
                className="nav-link"
                activeClassName="active"
              >
                {this.state.currentEvent ? (
                  <>
                    <img
                      className="bottom-icon w-auto"
                      src={
                        this.state.currentEvent === "christmas"
                          ? christmas["promos-icon.webp"]
                          : this.state.currentEvent === "cny" ?
                            chinese["promos-icon.webp"]
                            : this.state.currentEvent === "raya" ?
                            raya["promos-icon.webp"]
                            : this.state.currentEvent === "merdeka" && this.props.currentUser.country_id !== 4 ?
                            merdeka["promos-icon.webp"] 
                            : this.state.currentEvent === "mooncake" && this.props.currentUser.country_id !== 4 ?
                            mooncake["promos-icon.webp"] 
                            : this.state.currentEvent === "halloween" ?
                            halloween["promos-icon.webp"]
                            : img["promo-icon-bottom-nav-mobile.webp"]
                      }
                      alt="promo icon"
                    />
                    <img
                      className="bottom-icon-active w-auto"
                      src={
                        this.state.currentEvent === "christmas"
                          ? christmas["promos-icon-active.webp"]
                          : this.state.currentEvent === "cny" ?
                            chinese["promos-icon-active.webp"]
                            : this.state.currentEvent === "raya" ?
                            raya["promos-icon-active.webp"]
                            : this.state.currentEvent === "merdeka" && this.props.currentUser.country_id !== 4 ?
                            merdeka["promos-icon-active.webp"]
                            : this.state.currentEvent === "mooncake" && this.props.currentUser.country_id !== 4 ?
                            mooncake["promos-icon-active.webp"]
                            : this.state.currentEvent === "halloween" ?
                            halloween["promos-icon-active.webp"]
                            : img["promo-icon-bottom-nav-mobile-active.webp"]
                      }
                      alt="promo icon active"
                    />
                  </>
                ) : (
                  <>
                    <img
                      className="bottom-icon"
                      src={img["promo-icon-bottom-nav-mobile.webp"]}
                      alt="promo icon"
                    />
                    <img
                      className="bottom-icon-active"
                      src={img["promo-icon-bottom-nav-mobile-active.webp"]}
                      alt="promo icon active"
                    />
                  </>
                )}
                {/* <small className="badge">12 </small> */}
                {t("page-header.promos")}
              </NavLink>
            </li>
            <li>
              <NavLink
                to={{ pathname: "/sponsor" }}
                className="nav-link"
                activeClassName="active"
                style={{pointerEvents: "none"}}
              >
                {this.state.currentEvent ? (
                  <>
                    <img
                      className="bottom-icon w-auto"
                      src={
                        this.state.currentEvent === "christmas"
                          ? christmas["sponsor-icon.webp"]
                          : this.state.currentEvent === "cny" ?
                            chinese["sponsor-icon.webp"]
                            : this.state.currentEvent === "raya" ?
                            raya["sponsor-icon.webp"]
                            : this.state.currentEvent === "merdeka" && this.props.currentUser.country_id !== 4 ?
                            merdeka["sponsor-icon.webp"]
                            : this.state.currentEvent === "mooncake" && this.props.currentUser.country_id !== 4 ?
                            mooncake["sponsor-icon.webp"]
                            : this.state.currentEvent === "halloween" ?
                            halloween["sponsor-icon.webp"]
                            : img["sponsor-icon-bottom-nav-mobile.webp"]
                      }
                      alt="sponsor icon"
                    />
                    <img
                      className="bottom-icon-active w-auto"
                      src={
                        this.state.currentEvent === "christmas"
                          ? christmas["sponsor-icon-active.webp"]
                          : this.state.currentEvent === "cny" ?
                            chinese["sponsor-icon-active.webp"]
                            : this.state.currentEvent === "raya" ?
                            raya["sponsor-icon-active.webp"]
                            : this.state.currentEvent === "merdeka" && this.props.currentUser.country_id !== 4 ?
                            merdeka["sponsor-icon-active.webp"]
                            : this.state.currentEvent === "mooncake" && this.props.currentUser.country_id !== 4 ?
                            mooncake["sponsor-icon-active.webp"]
                            : this.state.currentEvent === "halloween" ?
                            halloween["sponsor-icon-active.webp"]
                            : img["sponsor-icon-bottom-nav-mobile-active.webp"]
                      }
                      alt="sponsor icon active"
                    />
                  </>
                ) : (
                  <>
                    <span>{t("global.stay-tuned")}</span>
                    <img
                      className="bottom-icon"
                      src={img["sponsor-icon-bottom-nav-mobile.webp"]}
                      alt="sponsor icon"
                    />
                    <img
                      className="bottom-icon-active"
                      src={img["sponsor-icon-bottom-nav-mobile-active.webp"]}
                      alt="sponsor icon active"
                    />
                  </>
                )}

                {t("global.sponsor")}
              </NavLink>
            </li>
            <li>
              <NavLink
                to={{ pathname: `/online-live-games-${dynamicCountry}` }}
                className="nav-link home-btn"
                activeClassName="active"
              >
                {this.state.currentEvent ? (
                  <>
                    <img
                      className="bottom-icon"
                      src={
                        this.state.currentEvent === "christmas"
                          ? christmas["home-icon.webp"]
                          : this.state.currentEvent === "cny" ?
                            chinese["home-icon.webp"]
                            : this.state.currentEvent === "raya" ?
                            raya["home-icon.webp"]
                            : this.state.currentEvent === "merdeka" && this.props.currentUser.country_id !== 4 ?
                            merdeka["home-icon.webp"]
                            : this.state.currentEvent === "mooncake" && this.props.currentUser.country_id !== 4 ?
                            mooncake["home-icon.webp"]
                            : this.state.currentEvent === "halloween" ?
                            halloween["home-icon.webp"]
                            : img["home-icon-bottom-nav-mobile.webp"]
                      }
                      alt="home icon"
                    />
                    <img
                      className="bottom-icon-active"
                      src={
                        this.state.currentEvent === "christmas"
                          ? christmas["home-icon-active.webp"]
                          : this.state.currentEvent === "cny" ?
                            chinese["home-icon-active.webp"]
                            : this.state.currentEvent === "raya" ?
                            raya["home-icon-active.webp"]
                            : this.state.currentEvent === "merdeka" && this.props.currentUser.country_id !== 4 ?
                            merdeka["home-icon-active.webp"]
                            : this.state.currentEvent === "mooncake" && this.props.currentUser.country_id !== 4 ?
                            mooncake["home-icon-active.webp"]
                            : this.state.currentEvent === "halloween" ?
                            halloween["home-icon-active.webp"]
                            : img["home-icon-bottom-nav-mobile-active.webp"]
                      }
                      alt="home icon active"
                    />
                  </>
                ) : (
                  <>
                    <img
                      className="bottom-icon"
                      src={img["home-icon-bottom-nav-mobile.webp"]}
                      alt="home icon"
                    />
                    <img
                      className="bottom-icon-active"
                      src={img["home-icon-bottom-nav-mobile-active.webp"]}
                      alt="home icon active"
                    />
                  </>
                )}

                {/* {t("profile-sidebar.home")} */}
              </NavLink>
            </li>

            <li>
              <NavLink
                to={{ pathname: "/#" }}
                onClick={window["liveChatButton"]}
                className="nav-link"
                activeClassName="active"
              >
                {this.state.currentEvent ? (
                  <>
                    <img
                      className="bottom-icon w-auto"
                      src={
                        this.state.currentEvent === "christmas"
                          ? christmas["live-icon.webp"]
                          : this.state.currentEvent === "cny" ?
                            chinese["live-icon.webp"]
                            : this.state.currentEvent === "raya" ?
                            raya["live-icon.webp"]
                            : this.state.currentEvent === "merdeka" && this.props.currentUser.country_id !== 4 ?
                            merdeka["live-icon.webp"]
                            : this.state.currentEvent === "mooncake" && this.props.currentUser.country_id !== 4 ?
                            mooncake["live-icon.webp"]
                            : this.state.currentEvent === "halloween" ?
                            halloween["live-icon.webp"]
                            : img["chat-icon-bottom-nav-mobile.webp"]
                      }
                      alt="live icon"
                    />
                    <img
                      className="bottom-icon-active w-auto"
                      src={
                        this.state.currentEvent === "christmas"
                          ? christmas["live-icon-active.webp"]
                          : this.state.currentEvent === "cny" ?
                            chinese["live-icon-active.webp"]
                            : this.state.currentEvent === "raya" ?
                            raya["live-icon-active.webp"]
                            : this.state.currentEvent === "merdeka" && this.props.currentUser.country_id !== 4 ?
                            merdeka["live-icon-active.webp"]
                            : this.state.currentEvent === "mooncake" && this.props.currentUser.country_id !== 4 ?
                            mooncake["live-icon-active.webp"]
                            : this.state.currentEvent === "halloween" ?
                            halloween["live-icon-active.webp"]
                            : img["chat-icon-bottom-nav-mobile-active.webp"]
                      }
                      alt="live icon active"
                    />
                  </>
                ) : (
                  <>
                    <img
                      className="bottom-icon"
                      src={img["chat-icon-bottom-nav-mobile.webp"]}
                      alt="chat icon"
                    />
                    <img
                      className="bottom-icon-active"
                      src={img["chat-icon-bottom-nav-mobile-active.webp"]}
                      alt="chat icon active"
                    />
                  </>
                )}

                {t("contact-us.live-chat")}
              </NavLink>
            </li>
            {!localStorage.getItem('accessToken') ? (
              <li>
                <NavLink
                  to={{ pathname: "/vip" }}
                  className="nav-link"
                  activeClassName="active"
                >
                  {this.state.currentEvent ? (
                    <>
                      <img
                        className="bottom-icon w-auto"
                        src={
                          this.state.currentEvent === "christmas"
                            ? christmas["vip-icon.webp"]
                            : this.state.currentEvent === "cny" ?
                              chinese["vip-icon.webp"]
                              : this.state.currentEvent === "raya" ?
                              raya["vip-icon.webp"]
                              : this.state.currentEvent === "merdeka" && this.props.currentUser.country_id !== 4 ?
                              merdeka["vip-icon.webp"] 
                              : this.state.currentEvent === "mooncake" && this.props.currentUser.country_id !== 4 ?
                              mooncake["me-icon.webp"] 
                              : this.state.currentEvent === "halloween" ?
                              halloween["vip-icon.webp"]
                              : 
                              vipIcon["vip-icon-home-mobile.webp"]
                        }
                        alt="vip icon"
                      />
                      <img
                        className="bottom-icon-active w-auto"
                        src={
                          this.state.currentEvent === "christmas"
                            ? christmas["vip-icon.webp"]
                            : this.state.currentEvent === "cny" ?
                              chinese["vip-icon.webp"]
                              : this.state.currentEvent === "raya" ?
                              raya["vip-icon.webp"]
                              : this.state.currentEvent === "merdeka" && this.props.currentUser.country_id !== 4 ?
                              merdeka["vip-icon-active.webp"]
                              : this.state.currentEvent === "mooncake" && this.props.currentUser.country_id !== 4 ?
                              mooncake["me-icon-active.webp"]
                              : this.state.currentEvent === "halloween" ?
                              halloween["vip-icon-active.webp"]
                              : 
                              vipIcon["vip-icon-home-mobile.webp"]
                        }
                        alt="vip icon active"
                      />
                    </>
                  ) : (
                    <>
                      <img
                        className="bottom-icon"
                        src={vipIcon["vip-icon-home-mobile.webp"]}
                        alt="vip icon"
                      />
                      <img
                        className="bottom-icon-active"
                        src={vipIcon["vip-icon-home-mobile.webp"]}
                        alt="vip icon active"
                      />
                    </>
                  )}
                  {/* <small className="badge">12 </small> */}

                  {t("page-header.vip")}
                </NavLink>
              </li>
            ) : (
              <li>
                <NavLink
                  to={{ pathname: "/me" }}
                  className="nav-link"
                  activeClassName="active"
                >
                  {this.state.currentEvent ? (
                    <>
                      <img
                        className="bottom-icon"
                        src={
                          this.state.currentEvent === "christmas"
                            ? christmas["me-icon.webp"]
                            : this.state.currentEvent === "cny" ?
                              chinese["me-icon.webp"]
                              : this.state.currentEvent === "raya" ?
                              raya["me-icon.webp"] 
                              : this.state.currentEvent === "merdeka" && this.props.currentUser.country_id !== 4 ?
                              merdeka["me-icon.webp"]
                              : this.state.currentEvent === "mooncake" && this.props.currentUser.country_id !== 4 ?
                              mooncake["me-icon.webp"]
                              : this.state.currentEvent === "halloween" ?
                              halloween["me-icon.webp"] 
                              : 
                              img["me-icon-bottom-nav-mobile.webp"]
                        }
                        alt="show pwd"
                      />
                      <img
                        className="bottom-icon-active"
                        src={
                          this.state.currentEvent === "christmas"
                            ? christmas["me-icon-active.webp"]
                            : this.state.currentEvent === "cny" ?
                              chinese["me-icon-active.webp"]
                              : this.state.currentEvent === "raya" ?
                              raya["me-icon-active.webp"]
                              : this.state.currentEvent === "merdeka" && this.props.currentUser.country_id !== 4 ?
                              merdeka["me-icon-active.webp"]
                              : this.state.currentEvent === "mooncake" && this.props.currentUser.country_id !== 4 ?
                              mooncake["me-icon-active.webp"]
                              : this.state.currentEvent === "halloween" ?
                              halloween["me-icon-active.webp"]
                              :img["me-icon-bottom-nav-mobile-active.webp"]
                        }
                        alt="show pwd"
                      />
                    </>
                  ) : (
                    <>
                      <img
                        className="bottom-icon w-auto"
                        src={img["me-icon-bottom-nav-mobile.webp"]}
                        alt="show pwd"
                      />
                      <img
                        className="bottom-icon-active w-auto"
                        src={img["me-icon-bottom-nav-mobile-active.webp"]}
                        alt="show pwd"
                      />
                    </>
                  )}

                  {t("global.me")}
                </NavLink>
              </li>
            )}
          </ul>
        </section>
      </Auxiliary>
    );  
  }
}

const mapStateToProps = (state) => {
  return {
    currentUser: state.currentUser.data,
  };
};

const mapDispatchToProps = {
  getUserData,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withNamespaces("translation")(MobileBottomNav));
