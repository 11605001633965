import React, { Component } from 'react';
import Auxiliary from "../../../hoc/Auxiliary/Auxiliary";
import { withNamespaces } from "react-i18next";
import { getUserData } from "../../../store/ducks/currentUser/actions";
import { connect } from 'react-redux';
import { isDesktop } from 'react-device-detect';
import * as actions from "../../../store/ducks/cny/actions";
import { Fade } from "react-awesome-reveal";
import MobileCnyLuckyDraw from './MobileCnyLuckyDraw';
import Swal from "sweetalert2";
export class CnyLuckyDraw extends Component {
    constructor(props) {
        super(props);
        this.state = {
            eventEnd: false,
            qualificationStatus: false,
            buttonDisabled: false,
            depositAmount: true,
            currentDeposit: parseFloat(0).toFixed(2), 
            currentValid: parseFloat(0).toFixed(2), 
            participatedAmount: 0, 
            ticketAmount: 0, 
            releaseWinList: '',
            startDate: '',
            endDate: '',
        };
    }

    componentDidMount() {
        const { t } = this.props;

        this.props.getUserLuckData()

        if (this.props.currentUser.country_code !== 'MY') {
            return window.location.href = '/';
        }
    }

    shouldComponentUpdate(nextProps, nextState) {

        const { t } = this.props;

        if (nextProps.rs !== this.props.rs && nextProps.rs !== undefined) {
            if (nextProps.rs.responseCode === 200 && nextProps.rs.msgType === "success") {
                const total_deposit = nextProps.rs.data.total_deposit;
                const total_turnover = nextProps.rs.data.total_turnover;
                const avaliable_ticket_participated = nextProps.rs.data.avaliable_ticket_participated;
                const ticket_participated = nextProps.rs.data.ticket_participated;
                const release_win_list = nextProps.rs.data.release_win_list?nextProps.rs.data.release_win_list:`2024-03-19 12:00:00`;
                const start_date = nextProps.rs.data.start_date;
                const end_date = nextProps.rs.data.end_date?nextProps.rs.data.end_date:`2024-03-03 23:59:59`;

                this.setState({
                    currentDeposit: parseFloat(total_deposit).toFixed(2), 
                    currentValid: parseFloat(total_turnover).toFixed(2), 
                    participatedAmount: ticket_participated, 
                    ticketAmount: avaliable_ticket_participated,
                    releaseWinList: release_win_list,
                    startDate: start_date,
                    endDate: end_date,
                });

                const today = new Date();
                const buttonExpiredDate = new Date(end_date);
                if (today >= buttonExpiredDate) {
                    this.setState({ buttonDisabled: true });
                    if (this.checkedButtonRef) {
                        this.checkedButtonRef.disabled = true
                    }
                }
        
                const expired = new Date(release_win_list);// winner list show date
                if (today >= expired) {
                    this.setState({ eventEnd: true });
                }
            }
        } 
        

        if (nextProps.claim_result !== this.props.claim_result && nextProps.claim_result !== undefined) {
            if (nextProps.claim_result.responseCode === 200 && nextProps.claim_result.msgType === "success") {
                if (nextProps.claim_result.data.eligible === true) {
                    Swal.fire({
                        iconHtml: (!isDesktop) ? '<img src="https://api.iconify.design/ri:check-fill.svg?color=%2306DB04">' : '',
                        icon: 'success',
                        title: t('register.congratulation'),
                        html: t('cny-event.congrats-msg'),
                        confirmButtonText: t('global.confirm'),
                        customClass: (!isDesktop) ? {
                            container: 'swal-mobile-container sucess',
                            icon: 'swal-success-icon',
                        } : [],
                    }).then((result) => {
                        if (result.isConfirmed) {
                            // this.setState({ buttonDisabled: true });
                            window.location.href = '/cny-lucky-draw';
                        }
                    });
                } else {
                    Swal.fire({
                        iconHtml: (!isDesktop) ? '<img src="https://api.iconify.design/iconamoon:pensive-face-thin.svg?color=%2393a2Aa">' : '',
                        icon: "error",
                        title: t("global.sorry"),
                        text: t('cny-event.sorry-msg'),
                        showCancelButton: true,
                        confirmButtonText: t("norecord.deposit-now"),
                        cancelButtonText: t("global.confirm"),
                        customClass: (!isDesktop) ? {
                            container: 'swal-mobile-container failed',
                            icon: 'swal-sorry-icon'
                        } : [],
                    }).then((result) => {
                        if (result.isConfirmed) {
                            window.location.href = '/deposit';
                        }
                    });
                }
            } else {
                return false;
            }
        } 
        
        return true;

    }

    claimHandler = () => {
        // if (this.state.buttonDisabled) {
        //     return;
        // }

        let data = {};
        data.action = "click";
        data.member_id = this.props.currentUser.id
        data.country_id = this.props.currentUser.country_id
        this.props.funcApplyClaim(data)
    }

    imageImport = (r) => {
        let images = {};
        r.keys().map((item) => {
            return (images[item.replace("./", "")] = r(item)["default"]);
        });
        return images;
    };

    render() {
        const { t } = this.props;
        const tNc = this.imageImport(require.context("../../../assets/images/tnc", false, /\.(png|jpe?g|svg|webp)$/));
        const banner = this.imageImport(require.context("../../../assets/images/banners", false, /\.(png|jpe?g|svg|webp)$/));
        const prizeImg = this.imageImport(require.context("../../../assets/images/new-year-lucky-draw", false, /\.(png|jpe?g|svg|webp)$/));
        const chinese = this.imageImport(require.context("../../../assets/images/home/chinese", false, /\.(png|jpe?g|svg|webp)$/));

        let imageLanguage = '';
        switch (this.props.currentUser.language_code) {
            case "zh-CN":
                imageLanguage = "-cn";
                break;
            case "ms":
                imageLanguage = "-bm"
                break;
            case "indon":
                imageLanguage = "-bm"
                break;
            default:
                imageLanguage = "";
                break;
        }

        const drawTicketTable = [
            { deposit: 'MYR 48,000', valid: 'MYR 96,000', lucky: '45' },
            { deposit: 'MYR 33,000', valid: 'MYR 66,000', lucky: '40' },
            { deposit: 'MYR 23,000', valid: 'MYR 46,000', lucky: '33' },
            { deposit: 'MYR 13,000', valid: 'MYR 26,000', lucky: '18' },
            { deposit: 'MYR 5,000', valid: 'MYR 10,000', lucky: '10' },
            { deposit: 'MYR 3,000', valid: 'MYR 6,000', lucky: '6' },
            { deposit: 'MYR 1,000', valid: 'MYR 2,000', lucky: '3' },
            { deposit: 'MYR 500', valid: 'MYR 1,000', lucky: '1' },
        ]
        return (
            <Auxiliary>
                {isDesktop ?
                    (
                        <section className='pl-section cny'>
                            <img
                                className="master-bg cny"
                                src={chinese["master-bg.png"]}
                                alt="background"
                            />
                            <div className="banner-col">
                                <div className="banner-item">
                                    <img src={banner[`cny_2024${imageLanguage}.webp`]} alt="R77 Promos" />
                                </div>
                            </div>

                            <div className='x-container'>
                                <div className='section-header-gray mb-3 '>
                                    <h2>{t('cny-event.title')}</h2>
                                    <p>{t('cny-event.subtitle')}</p>
                                    <p className='event-date'>{t('cny-event.date')}</p>
                                </div>
                                {this.state.depositAmount && localStorage.getItem("accessToken")?
                                    <div className='cny-flower-label'>
                                        <div className='mb-5 mt-5'>
                                            <div className='section-header-gray mb-0'>
                                                <h2>{t('cny-event.lucky-draw-ticket')}</h2>
                                            </div>
                                            <p className='w-75 m-auto text-center'>{t('cny-event.ticket-1')}<span>{this.state.participatedAmount}</span>{t('cny-event.ticket-2')}<span>{this.state.ticketAmount}</span>{t('cny-event.ticket-3')}</p>
                                        </div>
                                        <div className='row cny-paper-btn justify-content-center mb-5 gap-5'>
                                            <div className='col-3'>
                                                <div className='cny-label'>
                                                    <h5>{t('cny-event.ticket-participated')}</h5>
                                                    <p>{this.state.participatedAmount}</p>
                                                </div>
                                            </div>
                                            <div className='col-3'>
                                                <div className='cny-label'>
                                                    <h5>{t('cny-event.ticket-balance')}</h5>
                                                    <p>{this.state.ticketAmount}</p>
                                                </div>

                                            </div>
                                        </div>
                                        <div className="d-flex align-items-center justify-content-center mt-5">
                                            {/* {(localStorage.getItem("accessToken")) ? */}
                                            <button
                                                className="d-flex align-items-center justify-content-center btn-gradient-blue btn w-25"
                                                onClick={this.claimHandler}
                                                // id="checked"
                                                disabled={this.state.buttonDisabled}
                                            // ref={(ref) => (this.checkedButtonRef = ref)}
                                            >
                                                {t('cny-event.participate-now')}
                                            </button>
                                            {/* :
                                            null
                                        } */}
                                        </div>

                                    </div>
                                    :
                                    ""}

                                <div className='mb-5 mt-5'>
                                    <div className='section-header-gray mb-0'>
                                        <h2>{t('cny-event.earn-lucky')}</h2>
                                    </div>
                                    <p className='w-75 m-auto text-center'>{t('cny-event.once-time')}</p>
                                </div>
                                {this.state.depositAmount && localStorage.getItem("accessToken")?
                                    <>
                                        <div className='row cny-paper-btn mb-5'>
                                            <div className='col-6'>
                                                <div className='cny-label'>
                                                    <h5>{t('cny-event.current-deposit')}</h5>
                                                    <p><span>MYR</span> {this.state.currentDeposit}</p>
                                                </div>
                                            </div>
                                            <div className='col-6'>
                                                <div className='cny-label'>
                                                    <h5>{t('cny-event.current-valid')}</h5>
                                                    <p><span>MYR</span> {this.state.currentValid}</p>
                                                </div>

                                            </div>
                                        </div>
                                    </>
                                    :
                                    ""}
                                <Fade duration={1000} triggerOnce>
                                    <div className='term-section cny'>
                                        <table className='table mb-0'>
                                            <thead>
                                                <tr>
                                                    <th>{t('cny-event.deposit-amount')}</th>
                                                    <th>{t('cny-event.valid-turnover')}</th>
                                                    <th>{t('cny-event.lucky-draw')}</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {
                                                    drawTicketTable && drawTicketTable.map((item, index) => (
                                                        <tr key={index}>
                                                            <td>{item.deposit}</td>
                                                            <td>{item.valid}</td>
                                                            <td>{item.lucky}</td>
                                                        </tr>
                                                    ))
                                                }
                                            </tbody>
                                        </table>
                                    </div>
                                    <p className="text-center mt-3" style={{ color: '#3e73fa' }}>{t('cny-event.note')}<span className='fw-bold'>{t('cny-event.deposit-amount')}</span>{t('cny-event.note-1')}<span className='fw-bold'>{t('cny-event.valid-turnover')}</span>{t('cny-event.note-2')}<span className='fw-bold'>{t('cny-event.lucky-draw')}</span></p>
                                </Fade>
                                <div className="d-flex align-items-center justify-content-center mt-3">
                                    { (localStorage.getItem("accessToken")) ? 
                                    <button
                                        className="d-flex align-items-center justify-content-center btn-gradient-blue btn w-25"
                                        onClick={this.claimHandler}
                                        id="checked"
                                        disabled={this.state.buttonDisabled}
                                    // ref={(ref) => (this.checkedButtonRef = ref)}
                                    >
                                        {t('norecord.deposit-now')}
                                    </button>
                                    :
                                        null
                                    }
                                </div>
                                {this.state.eventEnd ? (
                                    <>
                                        <div className='d-flex align-items-center justify-content-center mb-5 mt-5'>
                                            <span className='px-4'>
                                                <img className="w-75 h-auto object-fit-cover" src={prizeImg[`star.svg`]} alt="star icon" />
                                            </span>
                                            <div className='section-header-gray mb-0'>
                                                <h2>{t('christmas-event.lucky-winner')}</h2>
                                            </div>
                                            <span className='px-4'>
                                                <img className="w-75 h-auto object-fit-cover" src={prizeImg[`star.svg`]} alt="star icon" />
                                            </span>
                                        </div>
                                        <Fade duration={1000} triggerOnce>
                                            <div className='term-section christmas'>
                                                <table className='table'>
                                                    <thead>
                                                        <tr>
                                                            <th>{t('christmas-event.rank')}</th>
                                                            <th>{t('leaderboard.prize')}</th>
                                                            <th>{t('leaderboard.winner')}</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        <tr>
                                                            <td>{t('christmas-event.1st-prize')}</td>
                                                            <td>{t('cny-event.myr68000')}</td>
                                                            <td>A*a*w*n*77</td>
                                                        </tr>
                                                        <tr>
                                                            <td>{t('christmas-event.2nd-prize')}</td>
                                                            <td>{t('cny-event.myr38000')}</td>
                                                            <td>M*h*m*a*Ah</td>
                                                        </tr>
                                                        <tr>
                                                            <td>{t('christmas-event.3rd-prize')}</td>
                                                            <td>{t('cny-event.macbook')}</td>
                                                            <td>M*g*n*e*h99</td>
                                                        </tr>
                                                        <tr>
                                                            <td>{t('cny-event.4th-prize')}</td>
                                                            <td>{t('cny-event.iphone')}</td>
                                                            <td>B*b*8*9*</td>
                                                        </tr>
                                                        <tr>
                                                            <td>{t('cny-event.5th-more')}</td>
                                                            <td>{t('cny-event.myr688')}</td>
                                                            <td>C*o*n*a*<br />
                                                                S*e*h*n*oo<br />
                                                                B*l*y*a*23<br />
                                                                W*i*i*<br />
                                                                P*e*l*n*79<br />
                                                                M*d*a*8*</td>
                                                        </tr>
                                                    </tbody>
                                                </table>
                                            </div>
                                        </Fade>
                                    </>

                                ) : ("")}

                                <div className='section-header-gray mt-5'>
                                    <h3>{t('christmas-event.prize-to-won')}</h3>
                                    <p> {t('cny-event.minimun-deposit')} </p>
                                </div>

                                <div >
                                    <div className='d-flex align-items-end justify-content-center mb-5'>
                                        <div >
                                            <img className='w-100 h-auto object-fit-cover' src={prizeImg[`prize-2${imageLanguage}.webp`]} alt="xmas lucky draw two" />
                                        </div>
                                        <div>
                                            <img className='w-100 h-auto object-fit-cover' style={{ marginBottom: '-0.5rem' }} src={prizeImg[`prize-1${imageLanguage}.webp`]} alt="xmas lucky draw one" />
                                        </div>
                                        <div >
                                            <img className='w-100 h-auto object-fit-cover' src={prizeImg[`prize-3${imageLanguage}.webp`]} alt="xmas lucky draw three" />
                                        </div>
                                    </div>
                                    <div className='d-flex align-items-end justify-content-center gap-3'>
                                        <div>
                                            <img className='w-100 object-fit-cover' style={{ height: "389px" }} src={prizeImg[`prize-4${imageLanguage}.webp`]} alt="xmas lucky draw four" />
                                        </div>
                                        <div>
                                            <img className='w-100 object-fit-cover' style={{ height: "389px" }} src={prizeImg[`prize-5${imageLanguage}.webp`]} alt="xmas lucky draw five" />
                                        </div>
                                    </div>
                                </div>

                                <div className='term-section mt-5'>
                                    <div className='term-container card-shadow'>
                                        <img src={tNc[`term-condition${imageLanguage}.svg`]} alt="Terms Condition" />
                                        <div className='term-content'>
                                            <ol>
                                                <li>{t('cny-event.tnc-1')}</li>
                                                <li>{t('cny-event.tnc-2')}</li>
                                                <li>{t('cny-event.tnc-3')}</li>
                                                <li>{t('cny-event.tnc-4')}<span className='fw-bold'>{t('cny-event.tnc-4-1')}</span></li>
                                                <li>{t('cny-event.tnc-5')}</li>
                                                <li>{t('cny-event.tnc-6')}</li>
                                                <li>{t('cny-event.tnc-7')}</li>
                                                <li>{t('cny-event.tnc-8')}</li>
                                                <li>{t('cny-event.tnc-9')}</li>
                                                <li>{t('cny-event.tnc-10')}</li>
                                                <li>{t('cny-event.tnc-11')}</li>
                                            </ol>
                                        </div>
                                        <img src={tNc["term-footer.png"]} alt="Terms Condition footer" />
                                    </div>
                                </div>
                            </div>
                        </section>
                    )
                    :
                    (
                        <MobileCnyLuckyDraw
                            {...this.state}
                            {...this.props}
                            drawTicketTable={drawTicketTable}
                            claimHandler={this.claimHandler}
                        />
                    )
                }

            </Auxiliary>
        )
    }
}

const mapStateToProps = (state) => {
    return {
        currentUser: state.currentUser.data,
        claim_result: state.cny.claim_result,
        rs: state.cny.rs,
    };
}


const mapDispatchToProps = (dispatch) => {
    return {
        getUserData: (data) => dispatch(getUserData(data)),
        funcApplyClaim: (data) => dispatch(actions.claim(data)),
        getUserLuckData: (data) => dispatch(actions.getMemberLuckDrawInfo(data)),
    };
}
export default connect(mapStateToProps, mapDispatchToProps)(withNamespaces("translation")(CnyLuckyDraw));